import { render, staticRenderFns } from "./zhgd.vue?vue&type=template&id=bb43b134&scoped=true&"
import script from "./zhgd.vue?vue&type=script&lang=js&"
export * from "./zhgd.vue?vue&type=script&lang=js&"
import style0 from "./zhgd.vue?vue&type=style&index=0&id=bb43b134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb43b134",
  null
  
)

export default component.exports